@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: rgb(41, 46, 61);
  --foreground: rgb(245, 245, 245);
  --gold: rgb(252, 184, 96);
  --t-gold: rgba(252, 184, 96, 0.125);
  --secondary: rgb(26, 30, 39);
}

@mixin diamond {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  @apply bg-gold opacity-20 h-10 md:h-16;
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: var(--font-roboto), Helvetica, sans-serif;
  overflow-x: hidden;
}

.leaflet-popup {
  margin-bottom: 22px !important;

  .leaflet-popup-content {
    margin: 0 !important;
  }
}


main {
  min-height: 77svh, // 76.7svh;
}

h1,
h2,
h3 {
  font-family: var(--font-montserrat), Helvetica, sans-serif;
}

h1 {
  @apply text-4xl font-bold;
}

h2 {
  @apply text-3xl font-bold;
}

h3 {
  @apply text-2xl font-bold;
}

p {
  @apply text-lg text-justify;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }

  .font-roboto {
    font-family: var(--font-roboto);
  }

  .font-montserrat {
    font-family: var(--font-montserrat);
  }

  .font-work-sans {
    font-family: var(--font-workSans);
  }
}

@layer components {
  .diamond-thin {
    @include diamond;
    @apply w-3 md:w-6;
  }

  .diamond-normal {
    @include diamond;
    @apply w-8 md:w-12;
  }

  .btn {
    @apply active:scale-90 transition-all duration-300 ease-in-out rounded-lg;
  }

  .link-btn {
    @apply active:scale-90 hover:text-dark hover:bg-light transition-all duration-300 cursor-pointer border-2 border-solid text-xl py-4 px-6 md:px-8 lg:px-12 font-semibold disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:text-inherit disabled:hover:bg-inherit disabled:active:scale-100 disabled:transition-none;
  }

  .dropdown {
    @apply relative transition-all duration-300 cursor-pointer border-t border-b border-solid text-xl py-4 px-6 md:px-8 lg:px-12 font-semibold disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:text-inherit disabled:hover:bg-inherit disabled:active:scale-100 disabled:transition-none;
  }


  .link-btn-gold {
    @apply active:scale-90 text-dark bg-gold hover:bg-transparent hover:text-gold hover:border-gold border-2 border-transparent transition-all duration-300 cursor-pointer text-xl py-4 px-6 md:px-8 lg:px-12 font-semibold disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:text-inherit disabled:hover:bg-inherit disabled:hover:border-inherit disabled:active:scale-100 disabled:transition-none;
  }


  .p-main {
    @apply py-12 px-8 md:px-16 lg:px-24;
  }

  .form-section {
    @apply bg-black/60 flex flex-col gap-6 justify-center items-center text-light;
  }

}

.md-anchors {
  a {
    color: var(--gold);

    &:hover {
      text-decoration: underline;
    }

    &:active {
      color: var(--t-gold)
    }
  }
}

input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.3s ease-in-out forwards;
}

.fade-bg {
  position: relative;
  overflow: hidden;

  &::before {
    transition: opacity 0.3s ease-in;
    opacity: 0;
  }

  &.hovered::before {
    opacity: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 0;
    background-image: var(--background-image);
    background-position: var(--background-position);
  }

  &.hovered::after {
    opacity: 0.5;
  }
}

.bg-overlay {
  @apply relative;

  &::before {
    @apply absolute inset-0 bg-black/70 content-[""];
  }
}